function throttle (fn, threshold, scope) {
  threshold = threshold || 250;
  var last;
  var deferTimer;

  return function() {
    var context = scope || this;
    var now = new Date();
    var args = arguments;

    if (last && now < last + threshold) {
      clearTimeout(deferTimer);
      deferTimer = setTimeout(function() {
        last = now;
        fn.apply(context, args);
      }, threshold);
    } else {
      last = now;
      fn.apply(context, args);
    }
  };
}

//Ajax loading
(function( $ ){
  //Methods
  var busy = null,
      blockPopstateEvent = document.readyState !== 'complete',
      //Private functions
      callback = function(fn, url, $container) {

        if (fn !== false && typeof fn === 'function') {

          fn(url, $container);

        } else {

          console.log('The provided callback is not a function.');

        }

      },
      methods = {

      defaults : {
        wrapper: 'html',
        ajaxUrl: null,
        action: null,
        offset: null,
        container: 'html',
        anchors: 'a:not([target="_blank"]):not([href="#"])',
        siteName: null,
        beforeLoading: null,
        afterLoading: null
      },

      settings : {

      },

      init : function(options) {

        methods.settings = $.extend({}, methods.defaults, options);

        if ( window.history && window.history.pushState ) {

          methods.setListeners(methods.settings);

        } else {

          console.log('History is not supported by this browser.');

        }
      },

      setListeners : function(options) {

        var $wrapper = $(options.wrapper),
            $anchors = $wrapper.find(options.anchors),
            $container = $(options.container);

        $anchors.on('click', function(e) {

          var $anchor = $(this),
              url = $anchor.attr('href');

          if(url === window.location.href) {

            e.preventDefault();

          } else if ( e.which === 2 || e.metaKey ) {

            return true;

          }
          else{
            options.title = $anchor.text();
            methods.pushAndLoad(url, options);
          }

          e.preventDefault();

        });


        window.onload = function() {
          setTimeout(function(){
            blockPopstateEvent = false;
          }, 0);
        };

        window.onpopstate = function(e) {
          var url = window.location.href;
          if(blockPopstateEvent && document.readyState === 'complete') {
              e.preventDefault();
              e.stopImmediatePropagation();
              return false;
          }
          else if(url.search('#') === -1){
            callback(options.beforeLoading, url, $container);
            methods.loadContent(url, options);
          }
        };

      },

      pushAndLoad: function(url, options) {
        var $container = $(options.container);
        window.history.pushState(null, options.title, url);
        callback(options.beforeLoading, url, $container);
        methods.loadContent(url, options);
      },

      loadContent: function(url, options) {
        var $container = $(options.container);

        if(!busy) {
          busy = $.ajax({
              url: url,
              headers: {
                  'X-Requested-With':'BAWXMLHttpRequest'
              },
              dataType: 'html',
              success: function(data){
                $container.html(data).ajaxify('setListeners', options);
                callback(options.afterLoading, url, $container, null);
                busy = null;
              }
          });
        }
      },

      getContent: function(url, options) {
        var $container = $(options.container);

        callback(options.beforeLoading, url, $container, null);

        if(!busy) {
          busy = $.ajax({
              url: options.ajaxUrl,
              data: {
                action: options.action,
                offset: options.offset
              },
              dataType: 'html',

              success: function(data){

                callback(options.afterLoading, null, $container, data);
                busy = null;
              }
          });
        }
      }
    };



  //Init
  $.fn.ajaxify = function(methodOrOptions) {

    if ( methods[methodOrOptions] ) {

        return methods[ methodOrOptions ].apply( this, Array.prototype.slice.call( arguments, 1 ));

    } else if ( typeof methodOrOptions === 'object' || ! methodOrOptions ) {

        this.data('ajaxify-settings', $.extend(true, {}, $.fn.ajaxify.defaults, methodOrOptions));

        return methods.init.apply( this, arguments);

    } else {

        $.error( 'Method ' +  methodOrOptions + ' does not exist on jQuery.ajaxify' );

    }
  };
})( jQuery );

$.fn.setBackground = function(url) {
  var el = $(this),
      src = (url !== undefined) ? url : el.attr('data-background-url'),
      img = new Image();

  img.src = src;

  img.onload = function() {
    el.css({'background-image': 'url('+src+')'}).addClass('loaded');
    // setTimeout( function() {
    //   el.addClass('loaded');
    // }, 300);
  };
};

function findUrl(url, anchors) {
  var matching = anchors.filter('[href="'+url+'"]').length;

  return matching > 0 ? true : false;
}

$.fn.pageNav = function() {
    var nav = $(this),
        html = '';
        headings = $('.main').find('h1, h2');

    if(headings.length > 1) {
      headings.map(function(){
        var heading = $(this),
            title = heading.text(),
            slug = '',
            hash = '#';

        slug = title.toLowerCase();
        slug = slug.replace(/[?.\'`:\/]/g,'');
        slug = slug.replace(/ /g,'-');
        slug = slug.replace(/-$/, '');
        slug = slug.replace(/[éêèë]/g, 'e');
        slug = slug.replace(/[àâä]/g, 'a');
        slug = slug.replace(/[òô]/g, 'o');
        slug = slug.trim();
        hash += slug;


        heading.attr('id',slug);

        html +='<li><a data-target="'+ hash +'" href="#">' + title + '<span class="bullet"><i></i></span></li></a>';
      });

      nav.html(html);

      nav.find('li:first-child').addClass('active');

      nav.find('a').on('click', function(e) {
        var parent = $(this).parents('li'),
            targetID = $(this).attr('data-target'),
            target = $(targetID),
            position = target.offset().top - $('#menu').height() - 60;



        // parent.siblings('.active').removeClass('active');
        // parent.addClass('active');

        $('html, body').stop().animate({ 'scrollTop': position }, 700);

        e.preventDefault();
      });

      $(window).scroll(function(){
        var headings = $('.main').find('h1, h2'),
            scrollPos = $(window).scrollTop();

        headings.map(function(){
          var heading = $(this),
              id = heading.attr('id'),
              position = heading.offset().top,
              menuElement = $('.subpages a[data-target="#'+id+'"]').parents('li');

          if(position - scrollPos < $('#menu').height() + 100) {
            menuElement.addClass('active');
            menuElement.siblings('li').removeClass('active');
          }
          else if(scrollPos + $(window).height() === $(document).height()) {
            menuElement.filter(':last-child').addClass('active');
            menuElement.filter(':last-child').siblings('li').removeClass('active');
          }
        });
      });
    }
    else {
      nav.empty();
    }

    return nav;
};

// append new items to grid
function loadGridItems($grid, options) {
  var $loader = $('.loader');
  var catId = $('.filters .active').data('cat-id');
  var config = {
    url: ajaxurl + '',
    data: {
      action: 'load_posts',
      offset: options.offset
    }
  };

  console.log('catId', catId);

  if(catId) {
    console.log('hey');
    config.data.category = catId;
  }

  $loader.addClass('show');

  $.ajax(config).done(function(html) {
    var $html = $(html);
    $loader.removeClass('show');

    $grid.append($html).isotope( 'appended', $html );

    $grid.imagesLoaded( function() {
      $grid.isotope('layout');
    });
  });
}

//init isotope
function setGrid($grid){
  $filters = $('.filters');

  $grid.imagesLoaded( function() {
    $grid.isotope({
      itemSelector: '.item',
      transitionDuration: '0.25s',
      hiddenStyle: { opacity: 0 },
      visibleStyle: { opacity: 1 },
      masonry: { columnWidth: '.grid-sizer' }
    });

    $filters.children('.btn').on( 'click', function(e) {
      var $filter = $(this),
          activeClass = 'active',
          target = $(this).attr('data-filter'),
          active = $filter.hasClass(activeClass);

      var $allItems = $grid.data('isotope').items.map(function(item) { return item.element; });

      var catId = $filter.data('cat-id');

      if(!active) {
        $filters.children('.active').removeClass(activeClass);
        $filter.addClass(activeClass);

        $grid.isotope('remove', $allItems);

        loadGridItems($grid, {
          offset: 0
        });
      }

      e.preventDefault();
    });
  });
}

function initPage() {
    var $grid = $('.grid');
    //Init isotope
    if($grid.length > 0) {
        setGrid($grid);
        $(window).on('load resize', function() {
          $grid.isotope('layout');
        });

      $(window).scroll( throttle(function() {


        if($(window).scrollTop() === ($(document).height() - $(window).height())) {
            console.log('end reached');

            loadGridItems($grid, { offset: $grid.find('.item').length });
        }
      }, 150));
    }

    //Init page nav
    if($grid.length < 1) {
      $('.subpages .nav').pageNav();
    }
    else {
      $('.subpages .nav').empty();
    }

    //Wrap videos
    if($('iframe').length > 0) {
      $('iframe[src*="youtube.com"], iframe[src*="vimeo.com"]').wrap('<div class="video-container"></div>');
    }
}

function setVideo() {
  var attributes = ['playsinline', 'autoplay', 'muted', 'loop'];
  var main = document.querySelector('.main');

  var video = document.createElement('video');
  var oldVideo = document.querySelector('body .background-video');
  var mp4 = document.createElement('source');
  var webm = document.createElement('source');

  var sourceMP4 = main.getAttribute('data-video');
  var sourceWEBM = main.getAttribute('data-video-alt');

  function setUp() {
    mp4.type = 'video/mp4';
    mp4.src = sourceMP4;

    webm.type = 'video/webm';
    webm.src = sourceWEBM;

    attributes.forEach(function(attr) {
      video.setAttribute(attr, true);
    });

    video.classList.add('background-video');

    video.appendChild(mp4);
    video.appendChild(webm);

    $(video).insertAfter('.background');

    video.oncanplay = function() {
      console.log('video ready');
      video.classList.add('reveal');
    };
  }

  if(oldVideo) {
    oldVideo.classList.remove('reveal');
    setTimeout(function() {
      $(oldVideo).remove();

      if(sourceMP4 || sourceWEBM) {
        setUp();
      }
    }, 400);
  } else {
    setUp();
  }
}

function setVideoLightBox() {
  var lightbox = document.querySelector('.lightbox');
  var lightboxContent = lightbox.querySelector('.lightbox__content');
  var lightboxClose = lightbox.querySelector('.lightbox__close');
  var links = [].slice.call(document.querySelectorAll('.video-link'));

  var closeLightbox = function() {
    lightbox.classList.remove('reveal');
  };

  if(links.length) {
    lightbox.addEventListener('click', closeLightbox);
    lightboxClose.addEventListener('click', closeLightbox);

    links.forEach(function(link) {
      console.log(link);
      link.addEventListener('click', function(e) {
        var html = link.getAttribute('data-embed');

        console.log('html', html);

        e.preventDefault();

        lightboxContent.innerHTML = html;
        lightbox.classList.add('reveal');
      });
    });
  }
}

// //Gestion des cookies
// function scrollHeaderListener() {
//   $( window ).on('scroll resize', function() {
//     var banner = $('.banner'),
//         navHeight = banner.height(),
//         offset = $( window ).scrollTop();

//     if(offset > 0) {
//       banner.addClass('navbar-white');
//     }
//     else {
//       banner.removeClass('navbar-white');
//     }
//   });
// }

// // Gestion de la mention CNIL
// function getCookieExpireDate() {
//     var cookieTimeout = 34214400000; // Le nombre de millisecondes que font 13 mois
//     var date = new Date();
//     date.setTime(date.getTime() + cookieTimeout);
//     var expires = "; expires=" + date.toGMTString();
//     return expires;
// }

// // Retourne la chaine de caractère correspondant à nom=valeur
// function getCookie(NomDuCookie) {
//     if (document.cookie.length > 0) {
//         begin = document.cookie.indexOf(NomDuCookie + "=");
//         if (begin !== -1) {
//             begin += NomDuCookie.length + 1;
//             end = document.cookie.indexOf(";", begin);
//             if (end === -1){
//               end = document.cookie.length;
//             }
//             return unescape(document.cookie.substring(begin, end));
//         }
//     }
//     return null;
// }

// // Cette fonction est appelée pour afficher la demande de consentement
// function askConsent() {
//     var body = $('body');
//     var div = ' <div id="cookie-banner"><div class="hidden-print" id="consentement">' +
//         ' <div class="container">Le site stancom.fr utilise des cookies pour personnaliser votre visite et vous assurer une meilleure expérience. ' +
//         ' <br>En continuant votre visite, vous acceptez l’utilisation des cookies. ' +
//         ' Pour en savoir plus, <a href="/cookies">cliquez ici</a>' +
//         ' <button type="button" class="close" data-dismiss="modal">&times;</button>' +
//         ' </div>' +
//         ' </div></div>';
//     body.append(div);

//     $("#consentement [data-dismiss]").one('click', function() {
//         var consentCookie = getCookie('hasConsent');
//         if (!consentCookie) {
//             document.cookie = 'hasConsent=true; ' + getCookieExpireDate() + ' ; path=/';
//         }
//         $('#cookie-banner').addClass('closed');
//         $('#cookie-banner').one('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function(){
//           $(this).remove();
//         });
//     });
// }

(function($) {
  var Sage = {
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        var $nav = $('.navbar'),
            $menuOpen = $('.menu-open'),
            background = $('.main').data('background-url');

        $('.background').setBackground(background);

        $menuOpen.click(function(){

          if($menuOpen.hasClass('open')) {
            $menuOpen.removeClass('open');
            $nav.removeClass('overlay');
          }
          else {
            $menuOpen.addClass('open');
            $nav.addClass('overlay');
          }

        });

        $('.menu-item').click(function() {
          $menuOpen.removeClass('open');
          $nav.removeClass('overlay');
        });

        initPage();
        // setVideo();
        setVideoLightBox();

        $('body').ajaxify({
          wrapper: 'body',
          anchors: 'a:not([target="_blank"]):not([href="#"])',
          container: '.content',

          beforeLoading: function(url, $container, ev){
            var $anchorContainers = $('a[href="'+url+'"]').parents('li'),
                $sidebar = $('.subpages');

            $('.background').removeClass('loaded');

            $sidebar.addClass('loading');

            $anchorContainers.map(function(){
              var $anchorContainer = $(this);

              if($anchorContainer.hasClass('menu-item')) {

                $anchorContainer.addClass('current-menu-item');
                $anchorContainer.siblings('.current-menu-item, .current-page-ancestor').removeClass('current-menu-item current-page-ancestor');

              }

              if($anchorContainer.hasClass('subpage')) {

                $anchorContainer.addClass('active');
                $anchorContainer.siblings('.active').removeClass('active');
              }
            });

            // $('html, body').animate({ 'scrollTop': 0 });
            $('.content').addClass('loading');

          },

          afterLoading: function(url, $container){
            var prevLink = $('.page-header').attr('data-prev-link'),
                prevTitle = $('.page-header').attr('data-prev-title'),
                nextLink = $('.page-header').attr('data-next-link'),
                nextTitle = $('.page-header').attr('data-next-title'),
                background = $('.main').data('background-url'),
                pagePath = window.location.pathname,
                pageTitle = $('h1').text();

            ga('set', { page: pagePath, title: pageTitle });

            ga('send', 'pageview');

            window.fbAsyncInit = function() {
              FB.XFBML.parse();
            };

            if (typeof(FB) !== 'undefined') {
              window.fbAsyncInit();
            }

            document.querySelector('body').scrollTop = 0;

            $('.background').setBackground(background);

            initPage();
            // setVideo();
            setVideoLightBox();

            $('.subpages').removeClass('loading');

            //Set top and bottom nav links
            if(prevLink) {
              $('.prev-page').attr('href', prevLink);
              $('.prev-page').children('.link-title').text(prevTitle);
              $('.prev-page').removeClass('disabled');
            }
            else {
              $('.prev-page').addClass('disabled');
            }

            if(nextLink) {
              $('.next-page').attr('href', nextLink);
              $('.next-page').children('.link-title').text(nextTitle);
              $('.next-page').removeClass('disabled');
            }
            else {
              $('.next-page').addClass('disabled');
            }

            $('.content').imagesLoaded().always( function( instance ) {
                $('.content').removeClass('loading');
            });

          }
        });

        //Menu and scroll interaction
        $(window).scroll(function(){
          scrollPos = $(this).scrollTop();

          if(scrollPos > 0) {
            $('.prev-page').addClass('toggled');
            $('.navbar').addClass('sticky');
          } else {
            $('.prev-page').removeClass('toggled');
            $('.navbar').removeClass('sticky');
          }

          if(scrollPos > $('.navbar').height() + $('.mask-inner').height()) {
            $('.mask-inner').addClass('toggled');
          }
          else {
            $('.mask-inner').removeClass('toggled');
          }

          if(scrollPos + $(this).height() === $(document).height()) {
            $('.next-page').removeClass('toggled');
          } else {
            $('.next-page').addClass('toggled');
          }
        });

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
